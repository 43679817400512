<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as CONTACT_TITLE_TYPES } from './store'
import { ROUTES as CONTACT_TITLE_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../../components/thux-list/ThuxListMixin'

import ContactTitleTable from './ContactTitleTable'
import ContactTitleCommonFilters from './ContactTitleCommonFilters'
import ContactTitleEdit from './ContactTitleEdit.vue'

export default {
  name: 'ContactTitleList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': ContactTitleTable,
    'common-filters': ContactTitleCommonFilters,
    'component-detail': ContactTitleEdit,
    'component-edit': ContactTitleEdit
  },
  data () {
    return {
      CONTACT_TITLE_ROUTES,
      title: this.$t('Contact titles'),
      rolePerm: ['organization_contacttitle_list'],
      actionEnablePermission: ['organization_contacttitle_enable'],
      actionDisablePermission: ['organization_contacttitle_disable'],
      fieldName: 'code',
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...CONTACT_TITLE_TYPES.GENERIC.organization.contacttitle.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.CONTACT_TITLE_ROUTES.CONTACT_TITLE_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('organization_contacttitle_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: CONTACT_TITLE_TYPES.GENERIC.organization.contacttitle.LIST.MUTATIONS.setSelectedList,
      setSelectAll: CONTACT_TITLE_TYPES.GENERIC.organization.contacttitle.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...CONTACT_TITLE_TYPES.GENERIC.organization.contacttitle.LIST.ACTIONS
    })
  }
}
</script>
