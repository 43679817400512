<template>
  <thux-table
    :instance="instance"
    :instance-name="instanceName"
    :order-by-dict="orderByDict"
    :goBack="goBack"
    :list="list"
    :fieldName="fieldName"
    :fields="fields"
    :fields-column="fieldsColumn"
    :select-all="selectAll"
    :selected-list="selectedList"
    :get-local-selected-all="getLocalSelectedAll"
    :local-selected-list="localSelectedList"
    @check-all="checkAll('code')"
    @check-none="checkNone('code')"
    @set-selected-row="setSelectedRow($event, 'code')"
    :show-checks="showChecks"
    :showDetailButton="showDetailButton"
    @change-status="changeInstanceStatus"
    @sort-changed="orderTable"
    @remove-order-by="removeOrderFromTable"
    @open-detail-form="$emit('open-detail-form', $event)"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as CONTACT_TITLE_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxTableMixin from '../../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'

export default {
  name: 'ContactTitleTable',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  data () {
    return {
      fields: [
        {
          key: 'checks',
          label: this.$t('Checks')
        },
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'code',
          label: this.$t('Code'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'code'
        },
        {
          key: 'name',
          label: this.$t('Name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'name'
        },
        {
          key: 'abbreviation',
          label: this.$t('Abbreviation'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'abbreviation'
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'code', 'name', 'abbreviation'],
      fieldName: 'code'
    }
  },
  computed: {
    ...mapGetters({
      selectAll: CONTACT_TITLE_TYPES.GENERIC.organization.contacttitle.LIST.GETTERS.selectAll,
      selectedList: CONTACT_TITLE_TYPES.GENERIC.organization.contacttitle.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return true
    },
    showDetailButton () {
      return hasPerm('organization_contacttitle_retrieve')
    }
  },
  methods: {
    ...mapMutations({
      setList: CONTACT_TITLE_TYPES.GENERIC.organization.contacttitle.LIST.MUTATIONS.setList,
      setSelectedList:
        CONTACT_TITLE_TYPES.GENERIC.organization.contacttitle.LIST.MUTATIONS.setSelectedList,
      setSelectAll: CONTACT_TITLE_TYPES.GENERIC.organization.contacttitle.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...CONTACT_TITLE_TYPES.GENERIC.organization.contacttitle.LIST.ACTIONS
    })
  }
}
</script>
